<template>
  <div>
    <h1 id="authentication-header">
      Authentication
    </h1>
    <div class="flex-container">
      <div class="flex-cell">
        <div class="flex-item">
          <img id="small-saml-icon" src="@/assets/images/logo-saml.png" alt="googleDrive">
          <h1 class="provider">
            SAML
          </h1>
          <VasionButton
            v-if="samlIsConnected"
            id="button-add-saved"
            :classProp="'primary'"
            @vasionButtonClicked="samlConnectButtonClick()"
          >
            Edit SSO
          </VasionButton>
          <VasionButton
            v-else
            id="button-add-saved"
            :classProp="'secondary'"
            @vasionButtonClicked="samlConnectButtonClick()"
          >
            Connect SSO
          </VasionButton>
        </div>
      </div>
    </div>

    <VasionGeneralModal
      id="connect-saml-dialog"
      modalType="slot"
      :modalTitle="'Connect SAML'"
      :sync="showConnectSAML"
      :hideButtons="true"
      showTopRightCloseButton
      @noButtonClick="closeSAMLConfig"
    >
      <ConnectSAML @cancelButtonClick="closeSAMLConfig" @configSaved="closeSAMLConfig" />
    </VasionGeneralModal>
  </div>
</template>

<script>
import ConnectSAML from '@/components/admin/authentication/ConnectSAML.vue';

export default {
  name: 'Authentication',
  components: {
    ConnectSAML,
  },
  data: function () {
    return {
      showConnectSAML: false,
    }
  },
  computed: {
    samlConfig() { return this.$store.state.admin.samlConfig },
    samlIsConnected() { return this.samlConfig ? this.samlConfig.ID !== 0 : false },
  },
  created: function () {
    this.reloadSamlConfig()
  },
  methods: {
    closeSAMLConfig() { this.showConnectSAML = false },
    reloadSamlConfig() { this.$store.dispatch('admin/getSamlConfig') },
    samlConnectButtonClick() { this.showConnectSAML = true },
  },
}
</script>

<style lang="scss" scoped>
  #authentication-header {
    margin: 22px 0px 24px 16px;
  }
  #small-saml-icon {
    max-width: 200px;
  }
  .flex-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 15px;
    :nth-child(3) {
      padding-right: 0;
    }
  }

  .flex-container:nth-of-type(1) {
      padding-left: 5px;
  }

  .flex-cell {
    flex: 0 0 33%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding:0px 16px;
    box-sizing: border-box;
    width: 33%;
  }

  .flex-cell:before {
    content: '';
    display: table;
    padding-top: 100%;
  }
  .flex-item {
    flex-grow: 1;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(22, 23, 24, 0.15);
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .provider {
    margin-top: 20%;
    margin-bottom: 20%;
  }
</style>
