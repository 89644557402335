<template>
  <div class="saml-config-container">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-show="showConfiguration" class="configuration-div">
      <div class="full-width top-spacing">
        <table class="full-width">
          <tr>
            <td>
              <VasionCheckbox
                id="active"
                name="active"
                :checked="active"
                @change="active = !active"
              >
                Enable SAML Login
              </VasionCheckbox>
            </td>
          </tr>
        </table>
        <table class="full-width top-spacing">
          <tr>
            <td>
              <VasionInput
                id="config-name"
                v-model="configurationName"
                title="NAME"
                name="config-name"
                placeholder="Enter Name..."
                inputType="top-white"
                :required="true"
              />
            </td>
          </tr>
        </table>
        <table class="full-width top-spacing">
          <tr>
            <td>
              <VasionInput
                id="frontend-url-name"
                v-model="frontendURL"
                title="FRONT END URL"
                name="frontend-url-name"
                placeholder="Enter Front End URL..."
                inputType="top-white"
                :required="true"
              />
            </td>
          </tr>
        </table>
        <table class="full-width top-spacing">
          <tr>
            <td>
              <VasionInput
                id="entity-name"
                v-model="entityName"
                title="ENTITY NAME"
                name="entity-name"
                placeholder="Enter Entity Name..."
                inputType="top-white"
                :required="true"
              />
            </td>
          </tr>
        </table>
        <table class="full-width top-spacing">
          <tr>
            <td>
              <VasionInput
                id="assertion-consumer-service"
                v-model="vasionAPIURL"
                title="ASSERTION CONSUMER SERVICE (For ID Provider)"
                name="assertion-consumer-service"
                :isDisabled="true"
                :readonly="true"
                inputType="top-white"
              />
            </td>
          </tr>
        </table>
        <table class="full-width top-spacing">
          <tr>
            <td>
              <VasionInput
                id="certificate-display"
                v-model="certFileDisplay"
                title="CERTIFICATE"
                name="certificate-display"
                placeholder="Enter URL or Upload File..."
                inputType="top-white"
                :required="true"
              />
            </td>
            <td class="button-width">
              <VasionButton
                id="upload-cert-button"
                class="upload-button"
                :classProp="'primary'"
                @vasionButtonClicked="uploadCertClick"
              >
                Upload
              </VasionButton>
            </td>
          </tr>
        </table>
        <table class="full-width">
          <tr>
            <td class="half-width-left">
              <VasionInput
                id="sso-url"
                v-model="ssoURL"
                title="SSO URL"
                name="sso-url"
                placeholder="Enter SSO URL..."
                inputType="top-white"
              />
            </td>
            <td class="half-width-right">
              <VasionInput
                id="sso-binding"
                v-model="ssoBinding"
                title="SSO BINDING"
                name="sso-binding"
                placeholder="Enter SSO Binding..."
                inputType="top-white"
                :required="true"
              />
            </td>
          </tr>
          <tr>
            <td class="half-width-left">
              <VasionInput
                id="slo-url"
                v-model="sloURL"
                title="SLO URL"
                name="slo-url"
                placeholder="Enter SLO URL..."
                inputType="top-white"
                :required="true"
              />
            </td>
            <td class="half-width-right">
              <VasionInput
                id="slo-binding"
                v-model="sloBinding"
                title="SLO BINDING"
                name="slo-binding"
                placeholder="Enter SLO Binding..."
                inputType="top-white"
                :required="true"
              />
            </td>
          </tr>
          <tr>
            <td class="half-width-left">
              <VasionInput
                id="clock-skew-minutes"
                v-model="clockSkewMinutes"
                title="CLOCK SKEW MINUTES"
                name="clock-skew-minutes"
                inputType="top-white"
                :isNumericOnly="true"
              />
            </td>
            <td class="half-width-right">
              <VasionInput
                id="response-attribute-username"
                v-model="responseAttributeUsername"
                title="RESPONSE ATTRIBUTE USERNAME"
                name="response-attribute-username"
                placeholder="Enter Username..."
                inputType="top-white"
              />
            </td>
          </tr>
          <tr>
            <td class="half-width-left">
              <label class="vasion-input-label-top">SIGN AUTHENTICATION REQUEST *</label>
              <br><md-radio v-model="signAuthRequest" class="radio-button-spacing" value="true">
                True
              </md-radio>
              <br><md-radio v-model="signAuthRequest" class="radio-button-spacing" value="false">
                False
              </md-radio>
            </td>
            <td class="half-width-right">
              <label class="vasion-input-label-top">WANT SAML RESPONSE SIGNED *</label>
              <br><md-radio v-model="wantSamlResponseSigned" class="radio-button-spacing" value="true">
                True
              </md-radio>
              <br><md-radio v-model="wantSamlResponseSigned" class="radio-button-spacing" value="false">
                False
              </md-radio>
            </td>
          </tr>
          <tr>
            <td class="half-width-left">
              <label class="vasion-input-label-top radio-label">WANT ASSERTION SIGNED *</label>
              <br><md-radio v-model="wantAssertionSigned" class="radio-button-spacing" value="true">
                True
              </md-radio>
              <br><md-radio v-model="wantAssertionSigned" class="radio-button-spacing" value="false">
                False
              </md-radio>
            </td>
            <td class="half-width-right">
              <label class="vasion-input-label-top">WANT ASSERTION ENCRYPTED *</label>
              <br><md-radio v-model="wantAssertionEncrypted" class="radio-button-spacing" value="true">
                True
              </md-radio>
              <br><md-radio v-model="wantAssertionEncrypted" class="radio-button-spacing" value="false">
                False
              </md-radio>
            </td>
          </tr>
        </table>
        <table class="full-width top-spacing">
          <tr>
            <td>
              <VasionCheckbox
                id="logging"
                name="logging"
                :checked="logging"
                @change="logging = !logging"
              >
                Enable SAML Logging at Server
              </VasionCheckbox>
            </td>
          </tr>
        </table>
      </div>
      <div class="saml-config-footer full-width">
        <label class="error-text-label">{{ errorText }}</label>
        <VasionButton
          id="reload-button"
          name="reload-button"
          :classProp="'text'"
          @vasionButtonClicked="reloadButtonClick"
        >
          Reload Config
        </VasionButton>

        <VasionButton
          id="cancel-button"
          name="cancel-button"
          :classProp="'text'"
          @vasionButtonClicked="cancelButtonClick"
        >
          Cancel
        </VasionButton>

        <VasionButton
          id="save-button"
          name="save-button"
          :classProp="'primary'"
          :isDisabled="disableSaveButton"
          @vasionButtonClicked="saveButtonClick"
        >
          Save
        </VasionButton>
      </div>
    </div>
    <div v-if="!showConfiguration" class="upload-div">
      <file-pond
        ref="pond"
        class="upload-spacing new-document"
        :server="serverOptions"
        @processfile="finishedLoading"
      />
      <div class="saml-config-footer full-width">
        <VasionButton
          id="upload-cancel-button"
          name="upload-cancel-button"
          :classProp="'text'"
          @vasionButtonClicked="uploadCancelButtonClick"
        >
          Cancel
        </VasionButton>

        <VasionButton
          id="upload-continue-button"
          name="upload-continue-button"
          :classProp="'primary'"
          :isDisabled="!certFileBase64"
          @vasionButtonClicked="continueButtonClick"
        >
          Continue
        </VasionButton>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { toBase64 } from '@/store/helperModules/storage.module'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'ConnectSAML',
  components: {
    Loading,
  },
  data: function () {
    return {
      active: false,
      assertionConsumerService: '',
      certFileDisplay: '',
      certFileURL: '',
      certFileBase64: '',
      certFileName: '',
      clockSkewMinutes: 15,
      configurationName: '',
      entityName: '',
      errorText: '',
      fullPage: true,
      ID: 0,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      logging: false,
      originalCertFileDisplay: '',
      responseAttributeUsername: '',
      serverOptions: {
        process: this.processHandler,
      },
      showConfiguration: true,
      signAuthRequest: 'true',
      sloBinding: '',
      sloURL: '',
      ssoBinding: '',
      ssoURL: '',
      wantAssertionEncrypted: 'true',
      wantAssertionSigned: 'true',
      wantSamlResponseSigned: 'true',
      frontendURL: '',
    }
  },
  computed: {
    disableSaveButton() {
      return !this.configurationName
        || !this.certFileDisplay
        || !this.ssoBinding
        || !this.sloURL
        || !this.sloBinding
        || !this.signAuthRequest
        || !this.wantSamlResponseSigned
        || !this.wantAssertionSigned
        || !this.wantAssertionEncrypted
        || !this.frontendURL
        || !this.entityName
    },
    samlConfig() { return this.$store.state.admin.samlConfig },
    vasionAPIURL() {
       return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_API}${this.assertionConsumerService}`
    },

  },
  created: function () {
    if (!this.samlConfig) {
      return
    }

    this.active = this.samlConfig.active
    this.assertionConsumerService = this.samlConfig.assertionConsumerService
    this.certFileDisplay = this.samlConfig.certFileDisplay
    this.entityName = this.samlConfig.entityName
    this.originalCertFileDisplay = this.certFileDisplay
    this.clockSkewMinutes = this.samlConfig.clockSkewMinutes
    this.configurationName = this.samlConfig.configurationName
    this.ID = this.samlConfig.ID
    this.logging = this.samlConfig.logging
    this.responseAttributeUsername = this.samlConfig.responseAttributeUsername
    this.signAuthRequest = this.samlConfig.signAuthRequest.toString()
    this.sloBinding = this.samlConfig.sloBinding
    this.sloURL = this.samlConfig.sloURL
    this.ssoBinding = this.samlConfig.ssoBinding
    this.ssoURL = this.samlConfig.ssoURL
    this.wantAssertionEncrypted = this.samlConfig.wantAssertionEncrypted.toString()
    this.wantAssertionSigned = this.samlConfig.wantAssertionSigned.toString()
    this.wantSamlResponseSigned = this.samlConfig.wantSamlResponseSigned.toString()
    this.frontendURL = this.samlConfig.frontendURL
  },
  methods: {
    cancelButtonClick() {
      this.$emit('cancelButtonClick')
    },
    continueButtonClick() {
      this.certFileDisplay = this.certFileName
      this.showConfiguration = true
    },
    // eslint-disable-next-line
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        this.certFileName = file.name
        this.certFileBase64 = await toBase64(file)
        const indexOf = this.certFileBase64.indexOf(';base64,')
        if (indexOf >= 0) {
          this.certFileBase64 = this.certFileBase64.substring(indexOf + 8)
        }

        progress(true, 0, 1024);
        load(file.name)

        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        };
      } catch (err) {
        console.warn(err)
      }
    },
    async reloadButtonClick() {
      await this.$store.dispatch('admin/reloadSAMLConfiguration')
      this.errorText = 'SAML Configuration reloaded at server'
    },
    async saveButtonClick() {
      this.isLoading = true

      const savePayload = {
        ID: this.ID,
        active: this.active,
        configurationName: this.configurationName,
        certFileURL: '',
        certFileBase64: '',
        certFileName: '',
        entityName: this.entityName,
        logging: this.logging,
        ssoURL: this.ssoURL,
        ssoBinding: this.ssoBinding,
        sloURL: this.sloURL,
        sloBinding: this.sloBinding,
        clockSkewMinutes: this.clockSkewMinutes,
        responseAttributeUsername: this.responseAttributeUsername,
        signAuthRequest: this.signAuthRequest === 'true',
        wantAssertionSigned: this.wantAssertionSigned === 'true',
        wantSamlResponseSigned: this.wantSamlResponseSigned === 'true',
        wantAssertionEncrypted: this.wantAssertionEncrypted === 'true',
        frontendURL: this.frontendURL,
      }

      if (this.certFileBase64 && this.certFileDisplay === this.certFileName) {
        savePayload.certFileBase64 = this.certFileBase64
        savePayload.certFileName = this.certFileName
      } else if (this.originalCertFileDisplay !== this.certFileDisplay) {
        // the cert url changed, so update it
        savePayload.certFileURL = this.certFileName
      }

      const saveResult = await this.$store.dispatch('admin/saveSamlConfig', savePayload)

      this.isLoading = false
      if (saveResult) {
        this.$emit('configSaved')
      } else {
        this.errorText = 'An error occurred while saving the configuration.'
      }
    },
    uploadCancelButtonClick() {
      this.showConfiguration = true
    },
    uploadCertClick() {
      this.showConfiguration = false
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/light-theme-system-palette.scss';

  .upload-spacing {
    padding-top: 49px;
    padding-bottom: 48px;
  }

  .error-text-label {
    top: 12px;
    position: relative;
    color: red;
  }

  .radio-button-spacing {
    margin-top: 5px;
    margin-bottom: -10px;
  }

  .button-width {
    width: 40px;
    text-align: right;
  }

  .half-width-left {
    width: 50%;
    padding-right: 5px;
    padding-top: 15px;
  }

  .half-width-right {
    width: 50%;
    padding-left: 5px;
    padding-top: 15px;
  }

  .saml-config-container {
    width: 750px;
    overflow: none;
    margin-right: 10px;
  }

  .configuration-div {
    height: 720px;
  }

  .saml-config-footer {
    text-align: right;
  }

  .full-width {
    width: 100%;
  }

  .top-spacing {
    margin-top: 12px;
  }

  .upload-button {
    margin-top: 22px;
  }
  
::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
  color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
  border-color: $secondary !important;
}
::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
  background-color: $secondary !important;
}
</style>
